import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `L’Oréal Paris`,
	heading: 'Eye Love It Mascara Quiz.',
	emphasisedText: 'Helping customers find their signature eye look with an online quiz',
	text: [
		'We worked with L’Oréal Paris to design and build a promotional quiz to help their customers find their signature eye look!', 
		'User would complete a multiple choice quiz, get their results and then they could watch as the L’Oréal Paris makeup squad demonstrates how to create their very own personalised signature eye look. Plus, any customer who took the quiz received a 50% discount on L’Oréal Paris products needed to create that look.'
	],
	numbers: {
		'Entrants': '3000',
		'Signature looks': '4',
	},
};

export default function Loreal() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/LorealParis@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
